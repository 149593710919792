export const COMPANY_LINKS = {
    LIMEX: 'https://tb-m.com/products/products-limex/',
    CIRCILE_X: 'https://tb-m.com/business/circulation-material/',
    MAAR: 'https://service.maar.app/',
    ZAIMA: 'https://zaima.in/',
    CAREERS: 'https://tb-m.com/recruit/',
    SEMINAR: 'https://brightinnovation.jp/seminar/',
    RECRUIT: 'https://brightinnovation.jp/recruit/',
    BRIGHT_INNOVATION: 'https://brightinnovation.jp/about/',

    // plans
    TAYORI: 'https://tayori.com/f/upsell/',
    TAYORI_FAQ: 'https://tayori.com/q/scopex-faq/',
}
